<template>
    <div id="loginPage">
      <div class="header">
        <div class="title">
          <van-icon @click="$router.back();" v-if="step === 2" class="icon" name="arrow-left" />
          <span>ログイン</span>
        </div>
        <router-link class="nav" :to="{name: 'Register', params: {type: 1}}">
          <span class="name">新規登録</span>
          <van-icon class="icon" name="arrow" />
        </router-link>
      </div>
      <!--输入密码-->
      <div v-if="step === 2" class="container">
        <div class="tip">{{subData.email}}</div>
        <div class="input-list">
          <div class="input-item">
            <input class="input" v-model="subData.password" :type="passwordActive?'text':'password'" placeholder="パスワードを入力してください">
            <i class="icon icon-eyes" :class="{active: passwordActive}" @click="passwordActive = !passwordActive"></i>
          </div>
        </div>
        <div class="nav">
          <!--忘记密码-->
          <span @click="goForgot">パスワードを忘れる場合</span>
        </div>
        <div @click="onSubmit" class="submit-btn">
          <span>ログイン</span>
        </div>
      </div>
      <!--输入邮箱-->
      <div v-else class="container">
        <div class="input-list">
          <div class="input-item">
            <i class="icon icon-email"></i>
            <input class="input" v-model="subData.email" type="email" placeholder="メールアドレスを入力してください">
          </div>
        </div>
        <div @click="onSubmit" class="submit-btn">
          <span>次へ</span>
        </div>
      </div>
    </div>
</template>

<script>
import md5 from 'md5';
// import storage from 'store';
export default {
  name: 'Login',
  components: {},
  data () {
    return {
      subData: {
        email: '',
        password: ''
      },
      passwordActive: false,
      step: 1
    };
  },
  created () {
  },
  mounted () {
    this.init();
  },
  watch: {
    $route () {
      this.init();
    },
    'subData.password' (newValue) {
      const reg = /[\u4E00-\u9FA5]|\s*/g;
      this.subData.password = newValue.replace(reg, '');
    }
  },
  methods: {
    init () {
      const { $route: { params }, subData: { email }, $router } = this;
      let type = params.type * 1;
      if (!email && type === 2) {
        return $router.replace({
          params: {
            type: 1
          }
        });
      }
      this.step = type;
    },
    onSubmit () {
      let { subData: { email, password }, step, $utils, $router } = this;
      // 验证邮箱
      if ($utils.verifyEmail(email)) {

        this.$http.get('security/verification', { params: { account: email } }).then((res) => {
          if (res.data === true) {
            if (step === 1) {

              /* 跳转到登录的第二栏 */
              return $router.push({
                name: 'Login',
                params: {
                  type: 2
                }
              });
            }

            /* 验证密码 */
            if ($utils.verifyEmpty(password, 'パスワードを入力してください')) {

              /* 验证密码的长度是否大于6小于12 */
              if ($utils.verifyLengeRange(password, 'パスワードの長さは6〜12桁です', 6, 12)) {

                /* 调用登录接口 */
                this.$http.post('security/login', {
                  email: email,
                  password: md5(password)
                }, {

                  /* 阻止频繁触发 */
                  showLoading: true,
                  loadingText: 'ログイン中...'
                }).then((res) => {
                  // 存储token信息
                  $utils.storage.set('loginToken', res.data || {});
                  $utils.successToast({
                    message: 'ログイン成功！',
                    onClose: () => {
                      // 跳转到首页
                      $router.push({
                        name: 'Home'
                      });
                      $utils.storage.set('email', email || '');
                    }
                  });
                });
              }
            }
          } else {
            $utils.toast('未登録ユーザーは先に登録してください');
          }
        });

      }
    },
    goForgot () {
      const { subData: { email }, $utils, $dialog, $http } = this;
      $dialog.alert({
        title: 'パスワードを再設定',
        message: `パスワード再設定用リンクを${email}に送信しましす`,
        theme: 'round-button',
        confirmButtonText: '送信',
        closeOnClickOverlay: true
      }).then(() => {
        // 发送忘记密码接口
        $http.get('security/forgetPassEmail', {
          params: {
            email,
            url: 'user/pwChange/forgot'
          }
        }).then((res) => {
          $utils.successToast({
            message: '送信済み',
            onClose: () => {
              $utils.storage.set('email', email);
            }
          });
        });

      });

    }

  }
};
</script>

<style scoped lang="less">
  #loginPage {
    padding: 128px 28px;

    .header {
      margin-bottom: 86px;

      .title,
      .nav {
        display: flex;
        align-items: center;
      }

      .title {
        margin-bottom: 22px;
        font-size: 40px;
        font-weight: bold;
        line-height: 50px;
        color: #fff;

        .icon {
          margin-right: 10px;
        }
      }

      .nav {
        font-size: 28px;
        line-height: 38px;
        color: @primaryBlueColor;

        .icon {
          margin-left: 10px;
        }
      }
    }

    .container {
      .tip {
        margin-bottom: 30px;
        color: #aaa;
        text-align: center;
      }

      .input-list {
        .input-item {
          .icon-email {
            background-image: url("~@/assets/images/icon_news_06.png");
          }

          .icon-eyes {
            background-image: url("~@/assets/images/icon_eyesClose_01.png");

            &.active {
              background-image: url("~@/assets/images/icon_eyesOpen_01.png");
            }
          }
        }
      }

      .nav {
        margin-top: 42px;
        margin-bottom: -72px;
        font-size: 22px;
        line-height: 30px;
        color: #666;
      }

      .submit-btn {
        margin-top: 210px;
      }
    }
  }
</style>